import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from 'gatsby-background-image'
import Layout from "../components/layout"
import SEO from "../components/seo"

import { Container, Row, Col, Button } from 'react-bootstrap'

const Mcm = ({ location }) => {

  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "illust/mcm-back.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 80) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
			},
			mcmLogo: file(relativePath: { eq: "mcm-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
			outline: file(relativePath: { eq: "mcm_outline_p.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
      feature: file(relativePath: { eq: "mcm_feature.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
			},
			casestudy1: file(relativePath: { eq: "casestudy_mcm_image01.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
			casestudy2: file(relativePath: { eq: "casestudy_mcm_image02.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
      internetIcon: file(relativePath: { eq: "icons/internet.svg" }) {
        publicURL
      },
      startIcon: file(relativePath: { eq: "icons/start.svg" }) {
        publicURL
      },
      cloudUploadIcon: file(relativePath: { eq: "icons/cloud-upload.svg" }) {
        publicURL
      },
      earthIcon: file(relativePath: { eq: "icons/earth.svg" }) {
        publicURL
      }
    }
  `)

  return (
    <Layout location={location} title="GW-MCM System" >
      <SEO title="GW-MCM System" description="GW-MCM Systemについてご紹介いたします。" />

      <div className="page-head">
        <Container expand="lg">
          <BackgroundImage fluid={data.background.childImageSharp.fluid} id="page-head-image" >
            <Img fluid={data.mcmLogo.childImageSharp.fluid} className="mb-5" alt="GW-MCM Systemロゴ" />
            <h1>
              モノ/コトを<span className="p-blue">ネットワークでつなぎ</span>、<br className="d-none d-lg-block" />
              データ収集/分析(アナリティクス)や
              ⾃動化などによって<span className="p-blue">新たな価値</span>を創出。<br className="d-none d-lg-block" />
            </h1>
            <p className="mb-5">
              IoTにより今まで得られなかった多くのデータが得られるようになり、
              またその多くのデータを効率よく処理するためにクラウドを活⽤。<br className="d-none d-lg-block" />
              遠隔地であっても、機械の稼働状況などをWEB上でいつでも把握でき、
              ⾃動化によって、ヒューマンエラーの回避や削減、作業効率の向上を⽬指します。<br className="d-none d-lg-block" />
            </p>
            <Button
              variant="primary"
              className="py-4 px-5"
              href="https://www.g-wise.co.jp/mcm-special"
              target="_blank" rel="noopener noreferrer"
            >
              GW-MCM System 特設ページはこちら
            </Button>
          </BackgroundImage>
        </Container>
        <div className="bottom-border" />
      </div>

      <Container expand="lg" className="text-center">
        <h3 className="mb-5">サービス概要</h3>
        <Row className="mb-6 text-left align-items-center">
          <Col lg="auto">
            <p>
              インターネット経由でデータ送信できるIoT（Internet of Things）ユニットです。<br />
              サーバー⽤ソフトウェア、クラウドサービスなどサポートやサービスも充実。 <br />
              業務の効率化やスピードアップに貢献するソリューションを提供します。
            </p>
          </Col>
          <Col lg>
            <Img fluid={data.outline.childImageSharp.fluid} className="mx-auto" alt="サービス概要" />
          </Col>

        </Row>

        <h3 className="mb-5">GW-MCM Systemの特徴</h3>
        <Row className="mb-6 text-left align-items-center">
          <Col lg="auto">
            <p>
              GW-MCM Systemは製造機から汎⽤ポート経由または接点I/Oのオンオフ信号を直接取込めますので、<br />
              製造機メーカに依存することなく、稼働状態データを収集することができます。<br />
              本システムと電⼦あんどんを組み合わせることによって、⼯場全体の状況をリアルタイムで把握することができます。<br />
              さらに、インターネット経由で、遠隔地からでも状況を確認・操作することができます。
            </p>
          </Col>
          <Col lg>
            <Img fluid={data.feature.childImageSharp.fluid} className="ml-auto" alt="EcoChangeの特徴" />
            <p className="text-right text-muted small mt-2">固定別機械状況画面イメージ</p>
          </Col>
        </Row>

        <h3 className="mb-5">導⼊事例</h3>
        <Row className="text-center">
          <Col lg={6} className="mb-5">
            <div className="shadow-box h-100 px-5">
              <h2 className="mb-5">製造ライン装置監視</h2>
              <Img fluid={data.casestudy1.childImageSharp.fluid} className="mx-auto" alt="製造ライン装置監視" />
            </div>
          </Col>
          <Col lg={6} className="mb-5">
            <div className="shadow-box h-100 px-5">
              <h2 className="mb-5">電⼦あんどん表⽰</h2>
              <Img fluid={data.casestudy2.childImageSharp.fluid} className="mx-auto" alt="電⼦あんどん表⽰" />
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Mcm